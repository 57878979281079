import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';

import { PopUpBase } from '@/atoms';
import CalendlyForm from '@/molecules/calendly-form';

import type { FunctionComponent } from 'react';
import type { TypePopUpCalendlyProps } from './types';

/**
 * PopUpCalendly
 */
export const PopUpCalendly: FunctionComponent<TypePopUpCalendlyProps> = ({
  className = '',
  onClose,
  open,
  formProps,
}: TypePopUpCalendlyProps) => {
  const { t } = useTranslation('common');

  return (
    <PopUpBase
      className={`${className} relative overflow-visible bg-surface-01  text-text-primary md:min-w-[500px]`}
      state={open}
      funcionOnClose={onClose}
    >
      <div className="absolute left-[10%] top-[7%] block md:hidden">
        <div className="u-aspect-square relative w-10 md:w-14">
          <Image
            src="/assets/img/logo/logo-brown.svg"
            alt="Logo BecasaEssentials"
            aria-label="Logo BecasaEssentials"
            objectFit="contain"
            layout="fill"
          />
        </div>
      </div>
      <div className="flex max-w-[550px] flex-col gap-4  rounded-md bg-white p-8 shadow-md md:!mx-20 md:!my-7">
        <p className="text-subtitle--large">
          {t('components.organisms.popUpCalendly.title')}
        </p>
        <p className="text-body text-body--primary">
          {t('components.organisms.popUpCalendly.desc')}
        </p>
        <CalendlyForm {...formProps} />
      </div>
    </PopUpBase>
  );
};

PopUpCalendly.displayName = 'PopUpCalendly';
