import { useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';

import type { FunctionComponent } from 'react';
import type { TypeSelectProps } from './types';

/**
 * Select
 */
export const Select: FunctionComponent<TypeSelectProps> = ({
  className = '',
  elements = [],
  name,
  defaultValue,
  label,
  requiredMessage,
}: TypeSelectProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext();
  const { t } = useTranslation('common');

  const elemntIndex = elements.findIndex(
    (elemt) => defaultValue?.value === elemt.value.toString()
  );

  const selectValidation = (value: any) =>
    value !== '-1' || 'Please select a valid option';

  return (
    <div>
      <label htmlFor={name} className="text-other--label-form uppercase">
        {label}
        {requiredMessage && ' *'}
      </label>
      <select
        id={name}
        {...register(name, {
          ...(requiredMessage !== undefined
            ? {
                required: requiredMessage,
                validate: selectValidation,
              }
            : {
                required: requiredMessage,
              }),
        })}
        name={name}
        defaultValue={
          elemntIndex !== -1
            ? defaultValue
              ? defaultValue.value
              : 0
            : elemntIndex
        }
        className={`text-body ${
          errors[name]
            ? 'border-[#ff0000] text-[#ff0000] placeholder-[#ff0000]'
            : 'text-body--primary border-primary-400 text-primary-400 placeholder-primary-400'
        }   w-full cursor-pointer border-b  bg-transparent pb-2  outline-none ${className}`}
        onChange={async (e) => {
          setValue(name, e.target.value);
          await trigger(name);
        }}
      >
        {defaultValue && elemntIndex === -1 ? (
          <option value={defaultValue.value}>{defaultValue.label}</option>
        ) : null}
        {elements?.map((child, index) => (
          <option
            {...(child.disabled && { disabled: true })}
            className="w-full"
            key={index}
            value={child.value}
          >
            {child.label}
            {child.disabled ? t('components.atoms.select.soon') : ''}
          </option>
        ))}
      </select>
      {errors[name] && (
        <span className="text-other--detail text-[#ff0000]">
          {errors[name].message}
        </span>
      )}
    </div>
  );
};

Select.displayName = 'Select';
